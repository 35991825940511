import React, { useEffect } from 'react'
import { Flex, jsx, Box, Image, Grid } from 'theme-ui'
import Compute from 'components/compute'
import Layout from 'components/layout'
import SEO from 'components/seo'
import { compute } from 'helpers'
import { UNIT } from 'types'

const SIZES = [
  {
    w: 0.5,
    h: 0.5,
  },
  {
    w: 1,
    h: 1,
  },
  {
    w: 1.25,
    h: 1.25,
  },
  {
    w: 1.5,
    h: 1.5,
  },
  {
    w: 1.75,
    h: 1.75,
  },
  {
    w: 2,
    h: 2,
  },
  {
    w: 2.5,
    h: 2.5,
  },
  {
    w: 2.75,
    h: 2.75,
  },
  {
    w: 3,
    h: 3,
  },
  {
    w: 4,
    h: 4,
  },
]

export default function Pricing() {
  return (
    <Layout>
      <SEO title="Pricing" />
      <Grid
        columns={5}
        gap={0}
        sx={{ width: '100%', maxWidth: '900px', margin: '0 auto' }}
        px={2}
        pt={2}
      >
        <Box
          sx={{
            ...headers,
            i: {
              fontSize: ['7px', '14px', '14px'],
            },
          }}
        >
          Size <i>(inch)</i>
        </Box>
        <Box sx={{ ...headers }}>399pcs</Box>
        <Box sx={{ ...headers }}>400pcs</Box>
        <Box sx={{ ...headers }}>1Kpcs</Box>
        <Box sx={{ ...headers }}>2Kpcs</Box>
        {SIZES.map((val, key: any) => {
          const computations = compute(val.w, val.h, UNIT.IN)
          return (
            <>
              <Box key={key} sx={{ ...prices }}>
                {`${val.w} x ${val.h}`}
              </Box>
              <Box key={key} sx={{ ...prices }}>
                ₱ {computations.low}
              </Box>
              <Box key={key} sx={{ ...prices }}>
                ₱ {computations.med}
              </Box>
              <Box key={key} sx={{ ...prices }}>
                ₱ {computations.high}
              </Box>
              <Box key={key} sx={{ ...prices }}>
                ₱ {computations.ultra}
              </Box>
            </>
          )
        })}
      </Grid>
    </Layout>
  )
}

const headers = {
  fontWeight: 'bold',
  fontSize: ['18px', '25px', '25px'],
  textAlign: 'center',
}

const prices = {
  textAlign: 'center',
  fontWeight: ['normal', 'normal', '500'],
  fontSize: ['12px', '21px', '21px'],
  borderBottom: ['1px solid #FDC300', '2px solid #FDC300', '2px solid #FDC300'],

  padding: '15px 0',
}
